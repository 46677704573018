window.shared = {
  loading: {
    hide: () => {
      $('body').addClass('loaded').removeClass('loading');

      setTimeout(() => {
        $('body').css('overflow-y', 'overlay')
      }, 300);
    },
    show: () => {
      $('body').addClass('loading').removeClass('loaded').css('overflow-y', 'hidden');
    },
  }
};

export const languageSelector = () => {
  if ($('.choose-language').length) {
    $('.choose-language').on('click', function (e) {
      e.preventDefault();

      const url = $(this).data('url')
      const value = $(this).data('value')
      const data = {
        locale: value,
      }

      $.ajax({
        type: "PUT",
        url: url,
        data: data,
        headers: {
          'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content,
        },
        dataType: "script",
      });
    })
  }
}
