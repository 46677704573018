const requestVideoToken = (meetingId, type) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/tokens',
      type: 'POST',
      data: { video_token: 'true', [type]: meetingId },
      success: (data) => {
        if (data.success) resolve(data.result);
      },
      error: (error) => {
        reject(error)
      }
    })
  })
}

export default requestVideoToken
