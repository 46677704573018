export const handleStartVideo = (participant) => {
  participant.videoTracks.forEach(publication => publication.track.enable());

  document.querySelector('#cam').classList.remove('camera-disabled-icon');
  document.querySelector('#cam').classList.add('camera-enabled-icon');
};

export const handleStopVideo = (participant) => {
  participant.videoTracks.forEach(publication => publication.track.disable());

  document.querySelector('#cam').classList.add('camera-disabled-icon');
  document.querySelector('#cam').classList.remove('camera-enabled-icon');
};

export const handleStartAudio = (participant) => {
  participant.audioTracks.forEach(publication => publication.track.enable());

  document.querySelector('#mic').classList.add('mic-enabled-icon');
  document.querySelector('#mic').classList.remove('mic-disabled-icon');
  document.querySelector('#my-video > #remote-muted-mic').setAttribute('hidden', true);
};

export const handleStopAudio = (participant) => {
  participant.audioTracks.forEach(publication => publication.track.disable());

  document.querySelector('#mic').classList.remove('mic-enabled-icon');
  document.querySelector('#mic').classList.add('mic-disabled-icon');
  document.querySelector('#my-video > #remote-muted-mic').hidden = false;
};

export const handleStopShareScreen = (videoCall) => {
  if(videoCall.remoteScreenShareActive) return;

  videoCall.removeScreenShareFromSpeakerView();

  if(videoCall.localScreenShareActive) {
    videoCall.localScreenShareActive = false;
    videoCall.hideScreenShareNotificationBox();
  }

  videoCall.displayViewToggleAsEnabled();
  videoCall.displayScreenShareBtnAsEnabled();
};

export const handleStopConnect = (room) => {
  room.disconnect();

  window.location.href = $("#leave-call").data("back-url");
};

export const handleLocalShareScreen = (videoCall) => {
  const trackName = videoCall.screenTrack.name;

  if (trackName.includes("video-screen-share")) {
    let screenShareButton = document.querySelector('#share');
        screenShareButton.style.backgroundColor = '#00c4ff';

    let notificationBox = document.querySelector('.notification-box');
        notificationBox.style.display = 'block';

    videoCall.localScreenShareActive = true;

    videoCall.removeSpeakerFromDominantView();
    videoCall.addScreenShareToSpeakerView(videoCall.screenTrack, videoCall.localParticipant);
    videoCall.localParticipant.publishTrack(videoCall.screenTrack);
    videoCall.displayViewToggleAsDisabled();
  }
};
