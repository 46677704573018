/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Add Cropper & Chosen to the global object
require("/node_modules/chosen-js/chosen.jquery.js");
require("/node_modules/cropperjs/dist/cropper.js");
require("/node_modules/jquery-cropper/dist/jquery-cropper.js");

require("../src/video/index");
require('../src/registration_signature.js.erb');
require('../src/zoom-image');
require('../src/detect-cpdtrk');
require('../src/language-selector');
