$(function () {
  if (trackingBlocked()) {
    showAlert();
  }
});

const trackingBlocked = () => window.canRunCpd == undefined;

const showAlert = () => {
  const element = document.getElementById('cpd-blocked-alert');

  if (element) {
    element.classList.remove('d-none');
  }
}
