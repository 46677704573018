import SignaturePad from 'signature_pad';
import { v4 as uuidv4 } from 'uuid';

$(function () {
  //exit if not agreement page
  if($('.registration-agreement-page').length < 1) {
    return
  }

  const isEnableSignature = $('.registration-agreement-page').data('signature-enabled');
  if(isEnableSignature) {
    initSignature();
  }
});

function initSignature() {
  const canvas = document.querySelector(".signature-canvas");
  const signaturePad = new SignaturePad(canvas)

  $('.clear-signature-pad').on('click', function () {
    signaturePad.clear();
  });

  $('.submit-agreement').on('click', function (e) {
    e.preventDefault();
    if (signaturePad.isEmpty()) {
      $('.signature-pad-error').html(I18n.t('registrations.validations.signature.blank'))
      return false;
    }

    let signatureImage = signaturePad.toDataURL();
    fetch(signatureImage)
      .then(res => res.blob())
      .then(blob => {
        const formData = new FormData($('.agreement-form')[0]);
        const generatedId = uuidv4();
        const file = new File([blob], generatedId + ".png", { type: "image/png" });

        const newRegistrationFlow = (page.controller() == 'event_registrations' && $('.registration-agreement-page').length > 0);
        const url = newRegistrationFlow ? $('.agreement-form').attr('action') : $('.agreement-form').data('action');
        const paramName = newRegistrationFlow ? "agreement[signature]" : "registration[signature]";

        formData.append(paramName, file);

        if (newRegistrationFlow) {
          $.ajax({
            url: `${url}.json`,
            data: formData,
            type: 'PUT',
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function(response) {
              return window.location.href = response.redirect_to
            },
            error: function(error) {
              $('.signature-pad-error').html(I18n.t('flash.error_occurred'))
            }
          });

          return false;
        }

        $.ajax({
          url: url,
          data: formData,
          type: 'POST',
          dataType: 'json',
          contentType: false,
          processData: false,
          success: function(response) {
            if(response.success) {
              return window.location.href = response.redirect_to
            }

            $('.signature-pad-error').html(I18n.t('flash.error_occurred'))
          },
          error: function(error) {
            $('.signature-pad-error').html(I18n.t('flash.error_occurred'))
          }
        });
      })
  })
}
