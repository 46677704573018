const requestInfoParticipant = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${window.location.pathname}/participants`,
      type: 'GET',
      success: (data) => {
        if (data.success) resolve(data.result);
      },
      error: (error) => {
        reject(error)
      }
    })
  })
}

export default requestInfoParticipant
