import { connect, createLocalTracks, LocalVideoTrack } from "twilio-video";
import shareScreen from "./share_screen";
import requestInfoParticipant from "./request_info_participant";

export default class TwilioV1 {
  constructor() {
    this.initialize();
    this.room = null;
    this.localParticipant = null;
    this.disableBtnShareScreen = false;
  }

  initialize() {
    this.joinMeetingUrl = $(".meeting-room").data("joinUrl");
    this.leftMeetingURL = $(".meeting-room").data("leftUrl");

    $(window).bind("unload beforeunload", () => {
      if (this.isRoomConnected()) {
        this.room.disconnect();
        this.handleStopVideo();
      }
    });
  }

  isRoomConnected = () => {
    return this.room && this.room.state === 'connected';
  };

  startVideoCall = async (token, videoMeetingId) => {
    this.hideBtnAction();
    var tracks;

    try {
      tracks = await createLocalTracks({
        audio: true,
        video: { width: 640 },
      });
    } catch (error) {
      console.log(error);
    }

    if (!tracks) return;

    const localVideo = tracks.find((track) => track.kind === "video");

    $("#local-media").append(localVideo.attach());

    const room = await connect(token, {
      name: videoMeetingId,
      audio: true,
      tracks: tracks,
    });
    this.joinRoom();

    this.room = room;
    this.localParticipant = room.localParticipant;
    this.localUserID = this.localParticipant.identity.replace("individual-users-", "");

    this.localParticipant.on("trackEnabled", (participant) => {
      if (participant.kind === "video") {
        $("#stopVideo").show();
      } else if (participant.kind === "audio") {
        $("#stopAudio").show();
      }
    });

    this.localParticipant.on("trackDisabled", (participant) => {
      if (participant.kind === "video") {
        $("#startVideo").show();
      } else if (participant.kind === "audio") {
        $("#startAudio").show();
      }
    });

    this.updateParticipants(this.localParticipant.identity.replace("individual-users-", ""), true);
    this.initRoomAndParticipantEvents();

    this.handleInteractiveButtons();
    this.showBtnAction();
  };

  joinRoom = () => {
    $.ajax({
      type: "PUT",
      url: this.joinMeetingUrl
    });
  };

  initRoomAndParticipantEvents = () => {
    const $screenShareContainer = $("#screen-share-media");

    this.room.participants.forEach((participant) => {
      if (this.localParticipant.identity !== participant.identity) {
        const userID = participant.identity.replace("individual-users-", "");
        this.updateParticipants(userID, true);
        const remoteContainer = this.renderElementRemoteVideo(userID);

        participant.tracks.forEach((publication) => {
          if (publication.track) {
            const track = publication.track;
            setTimeout(function () {
              remoteContainer.append(track.attach());
            }, 1000);
          }
        });

        participant.on("trackSubscribed", (track) => {
          this.renderRemoteVideo(track, remoteContainer, $screenShareContainer);
        });

        participant.on("trackUnpublished", (track) => {
          $screenShareContainer.html("");
          this.disableBtnShareScreen = false;
        });
      }
    });

    this.room.on("participantConnected", this.onParticipantConnected);

    this.room.on("participantDisconnected", this.onParticipantDisconnected);

    this.room.on("disconnected", this.handleDisconnectedRoom);
  };

  // Button Actions
  handleInteractiveButtons = () => {
    $("#captureScreen").on("click", this.handleCaptureScreen);

    $("#stopScreenCapture").on("click", this.handleStopShareScreen);

    $("#stopConnect").on("click", this.handleStopConnect);

    $("#startVideo").on("click", this.handleStartVideo);

    $("#stopVideo").on("click", this.handleStopVideo);

    $("#startAudio").on("click", this.handleStartAudio);

    $("#stopAudio").on("click", this.handleStopAudio);
  };

  hideBtnAction = () => {
    $(".meeting-room .loading-spinner").removeClass("d-none");
    $(".meeting-room .video-section").addClass("d-none");
    $(".meeting-room .section-chat").addClass("d-none");
  };

  showBtnAction = () => {
    $(".meeting-room .loading-spinner").addClass("d-none");
    $(".meeting-room .video-section").removeClass("d-none");
    $(".meeting-room .section-chat").removeClass("d-none");
  };
  //! Button Actions

  // Handle Actions
  handleStartShareScreen = () => {
    if (!this.localParticipant && this.screenTrack) {
      return;
    }

    if (this.screenTrack.name === "video-screen-share") {
      $("#screen-share-media").html(this.screenTrack.attach());
    }

    this.localParticipant.publishTrack(this.screenTrack);

    $("#captureScreen").hide();
    $("#stopScreenCapture").show().css( "display", "inline-block" );
  };

  handleStopShareScreen = () => {
    if (!this.localParticipant && this.screenTrack) {
      return;
    }

    this.localParticipant.unpublishTrack(this.screenTrack);
    this.screenTrack.stop();

    $("#stopScreenCapture").hide();
    $("#captureScreen").show().css( "display", "inline-block" );
    $("#screen-share-media").html("");
  };

  handleStopConnect = () => {
    this.room.disconnect();
    window.location.href = $("#stopConnect").data("back-url");
  };

  handleStopVideo = () => {
    this.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable();
    });

    $("#stopVideo").hide();
  };

  handleStartVideo = () => {
    this.localParticipant.videoTracks.forEach((publication) => {
      publication.track.enable();
    });

    $("#startVideo").hide();
  };

  handleStartAudio = () => {
    this.localParticipant.audioTracks.forEach((publication) => {
      publication.track.enable();
    });

    $("#startAudio").hide();
  };

  handleStopAudio = () => {
    this.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable();
    });

    $("#stopAudio").hide();
  };

  handleCaptureScreen = () => {
    if (this.disableBtnShareScreen) return;

    // Create and preview your local screen.
    shareScreen(500, 800, (stream) => {
      this.screenTrack = new LocalVideoTrack(stream.getTracks()[0], { name: "video-screen-share" });

      this.screenTrack.on("started", this.handleStartShareScreen);
      this.screenTrack.on("stopped", this.handleStopShareScreen);
    });
  };
  //! Handle Actions

  // Handle room and participant events
  onParticipantConnected = (participant) => {
    const userID = participant.identity.replace("individual-users-", "");
    const $remoteContainer = this.renderElementRemoteVideo(userID);
    const $screenShareContainer = $("#screen-share-media");

    this.updateParticipants(userID, true);

    participant.on("trackUnpublished", (track) => {
      $screenShareContainer.html("");
      this.disableBtnShareScreen = false;
    });

    participant.on("trackSubscribed", (track) => {
      this.room.localParticipant.audioTracks.forEach((publication) => {
        this.localParticipant.publishTrack(publication.track);
      });

      this.room.participants.forEach((participant) => {
        if (this.localParticipant.identity !== participant.identity) {
          participant.audioTracks.forEach((publication) => {
            if (publication.track) {
              publication.track.attach();
            }
          });
        }
      });

      this.renderRemoteVideo(track, $remoteContainer, $screenShareContainer);
    });
  };

  handleDisconnectedRoom = () => {
    // Detach the local media elements
    this.leftMeetingJob(this.localUserID);

    if (this.screenTrack) this.localParticipant.unpublishTrack(this.screenTrack);

    this.localParticipant.tracks.forEach((publication) => {
      const attachedElements = publication.track.detach();
      const remoteContainer = $(".remote-media");

      attachedElements.forEach((element) => element.remove());
      remoteContainer.remove();
    });
  };

  onParticipantDisconnected = (participant) => {
    const userID = participant.identity.replace("individual-users-", "");
    const $remoteContainer = $(`#remote-media-${userID}`);

    this.updateParticipants(userID, false);
    $remoteContainer.remove();
    $("#screen-share-media").html("");
  };
  //! Handle room and participant events

  renderRemoteVideo = (track, $remoteContainer, $screenShareContainer) => {
    if (track.name === "video-screen-share") {
      $screenShareContainer.html(track.attach());
      this.disableBtnShareScreen = true;
    } else {
      if (track.kind === "audio") {
        track.attach();
      } else {
        $remoteContainer.html(track.attach());
      }
    }
  };

  renderElementRemoteVideo = (user_id) => {
    var elm = $(`#remote-media-${user_id}`);

    if (!elm[0]) {
      var str = `<div class="remote-media" id="remote-media-${user_id}"></div>`;
      var div = $(".meeting-room .media-section")[0];
      div.insertAdjacentHTML("beforeend", str);
      elm = $(`#remote-media-${user_id}`);
    }

    return elm;
  };

  updateParticipants = (user_id, joined) => {
    var current_user_id = $(".meeting-room").data("current-user-id");
    if (user_id === String(current_user_id)) return;

    if (joined) {
      setTimeout(() => {
        requestInfoParticipant()
          .then((result) => {
            $("#participants")[0].innerHTML = result;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1000);

      return;
    }

    var elm = $(".meeting-room #participants").find(`#participant-${user_id}`);
    elm.remove();
  };

  leftMeetingJob = (userID) => {
    const ua = navigator.userAgent;
    const isFirefox = ua.match(/firefox/gi);
    const isSafari = ua.indexOf('safari') > 0 && ua.indexOf('chrome') === -1;
    const data = { user_id: userID };

    if (isSafari && window.fetch) {
      fetch(this.leftMeetingURL, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        keepalive: true
      });
    } else {
      $.ajax({
        type: "PUT",
        async: !(isFirefox || isSafari),
        url: this.leftMeetingURL,
        data: data
      });
    }
  };
}
