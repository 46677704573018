export const checkDeviceSupport = async (cb) => {
  // Request to allow multimedia
  try {
    await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    })
  } catch (error) {
    console.log(error)
  }

  var checkDeviseSupportPromise = new Promise((resolve, reject) => {
    var MediaDevices = []
    var isHTTPs = location.protocol === "https:" || location.hostname == "localhost"
    var canEnumerate = false

    if (typeof MediaStreamTrack !== "undefined" && "getSources" in MediaStreamTrack) {
      canEnumerate = true
    } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
      canEnumerate = true
    }

    var hasMicrophone = false
    var hasSpeakers = false
    var hasWebcam = false

    var isMicrophoneAlreadyCaptured = false
    var isWebcamAlreadyCaptured = false

    var unsupportedMsg =
      `${I18n.t('notice.unsupported_browser')} <a href="https://chrome.google.com">Google Chrome</a>`
    var unsupportedResponse = {
      status: "unsupported_browser",
      message: unsupportedMsg,
    }

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      // Firefox 38+ seems having support of enumerateDevices
      navigator.enumerateDevices = function (callback) {
        navigator.mediaDevices.enumerateDevices().then(callback)
      }
    }

    if (!canEnumerate) {
      reject(unsupportedResponse)
    }

    if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
      navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack)
    }

    if (!navigator.enumerateDevices) {
      reject({
        status: "blocked",
        message: unsupportedMsg,
        devices: {
          hasMicrophone: hasMicrophone,
          hasWebcam: hasWebcam,
          isMicrophoneAlreadyCaptured: isMicrophoneAlreadyCaptured,
          isWebcamAlreadyCaptured: isWebcamAlreadyCaptured,
        },
      })
    }

    navigator.enumerateDevices(function (devices) {
      devices.forEach(function (_device) {
        var skip,
          device = {}

        for (var d in _device) {
          device[d] = _device[d]
        }

        if (device.kind === "audio") {
          device.kind = "audioinput"
        }

        if (device.kind === "video") {
          device.kind = "videoinput"
        }

        MediaDevices.forEach(function (d) {
          if (d.id === device.id && d.kind === device.kind) {
            skip = true
          }
        })

        if (skip) {
          return
        }

        if (!device.deviceId) {
          device.deviceId = device.id
        }

        if (!device.id) {
          device.id = device.deviceId
        }

        if (!device.label) {
          device.label = "Please invoke getUserMedia once."
          if (!isHTTPs) {
            device.label = "HTTPs is required to get label of this " + device.kind + " device."
          }
        } else {
          if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
            isWebcamAlreadyCaptured = true
          }

          if (device.kind === "audioinput" && !isMicrophoneAlreadyCaptured) {
            isMicrophoneAlreadyCaptured = true
          }
        }

        if (device.kind === "audioinput") {
          hasMicrophone = true
        }

        if (device.kind === "audiooutput") {
          hasSpeakers = true
        }

        if (device.kind === "videoinput") {
          hasWebcam = true
        }

        MediaDevices.push(device)
      })

      let devicesData = {
        hasMicrophone: hasMicrophone,
        hasWebcam: hasWebcam,
        isMicrophoneAlreadyCaptured: isMicrophoneAlreadyCaptured,
        isWebcamAlreadyCaptured: isWebcamAlreadyCaptured,
      }

      if (hasMicrophone && hasWebcam && isMicrophoneAlreadyCaptured && isWebcamAlreadyCaptured) {
        resolve({
          status: "allowed",
          devices: devicesData,
        })
      } else {
        reject({
          status: "blocked",
          message: I18n.t('notice.media_blocked'),
          devices: devicesData,
        })
      }
    })
  })

  checkDeviseSupportPromise.then(cb).catch((response) => {
    let mediaDevicesNotice = document.getElementById("mediaDevicesNotice")
    let { message, devices } = response

    if (!mediaDevicesNotice) {
      return
    }

    if (!(devices.hasMicrophone && devices.hasWebcam)) {
      mediaDevicesNotice.classList.remove("d-none")
      mediaDevicesNotice.innerHTML = message
      return
    }

    if (!(devices.isMicrophoneAlreadyCaptured && devices.isWebcamAlreadyCaptured)) {
      mediaDevicesNotice.classList.remove("d-none")
      mediaDevicesNotice.innerHTML = message
      return
    }
  })
}
