import { connect, createLocalTracks, LocalVideoTrack } from "twilio-video";
import { generateUUID } from 'helpers/random_helper';
import shareScreen from "./share_screen";
import requestInfoParticipant from "./request_info_participant";
import { isMobileDevice } from '../deviceDetection';
import {
  handleStopVideo,
  handleStartVideo,
  handleStartAudio,
  handleStopAudio,
  handleLocalShareScreen,
  handleStopShareScreen,
  handleStopConnect
} from './twilio_controls';

export default class TwilioV2 {
  constructor() {
    this.initialize();
    this.room = null;
    this.localParticipant = null;
    this.remoteScreenShareActive = false;
    this.localScreenShareActive = false;
    this.setVideoGridSize("speakerView");
    this.localUserName = this.getLocalUserName();
  }

  videoGridSize(view) {
    return {
      "galleryView": 8,
      "speakerView": 5,
      "fullScreenView": 3
    }[view];
  }

  getLocalUserName() {
    return document.querySelector('.meeting-room').dataset.userName;
  }

  setVideoGridSize(view) {
    this.numOfDisplayedVideos = this.videoGridSize(view);
    this.lastRemoteVideo = this.numOfDisplayedVideos;
  }

  initialize() {
    const { joinUrl, leftUrl } = $(".meeting-room").data();

    this.joinMeetingUrl = joinUrl;
    this.leftMeetingURL = leftUrl;

    $(window).on("unload beforeunload", () => {
      if (this.isRoomConnected()) {
        this.room.disconnect();
        handleStopVideo();
      }
    });
  }

  isRoomConnected = () => this.room?.state === 'connected';

  generateVideoTrackName = (videoType) => `${videoType}|${generateUUID()}|${this.localUserName}`;

  startVideoCall = async (token, videoMeetingId) => {
    var tracks;

    let videoConfig = {
      width: 1600,
      height: 900,
      name: this.generateVideoTrackName('desktop-video')
    };

    if(isMobileDevice()) videoConfig.name = this.generateVideoTrackName('mobile-video');

    try {
      tracks = await createLocalTracks({
        audio: true,
        video: videoConfig
      });
    } catch (error) {
      console.log(error);
    }

    if (!tracks) return;
    const localVideo = tracks.find((track) => track.kind === "video");

    let preferredVideoCodecs = [];

    var ua = navigator.userAgent;
    if(!(ua.match(/Android/i))){
      preferredVideoCodecs = ['VP8'];
    }

    const room = await connect(token, {
      name: videoMeetingId,
      audio: true,
      tracks: tracks,
      dominantSpeaker: true,
      preferredVideoCodecs,
    });
    this.renderRemoteWebcam(localVideo, room.localParticipant);
    this.joinRoom();

    this.room = room;
    this.localParticipant = room.localParticipant;
    this.localUserID = this.localParticipant.identity.replace("individual-users-", "");

    // stop media upon joining room.
    handleStopAudio(this.localParticipant);
    document.querySelector('#mic').setAttribute('enabled', 'false');

    this.room.on("dominantSpeakerChanged", (participant) => {
      if (participant == null) return;
      if (this.screenShareInProgress()) return;
      if (this.numOfDisplayedVideos == 8) return;

      this.removeSpeakerFromDominantView();
      this.addSpeakerToDominantView(participant);
    });

    this.initRoomAndParticipantEvents();
    const userId = this.getUserId(this.localParticipant);
    this.updateParticipantsList(userId);
    this.setListenersForInteractiveButtons();

    document.querySelector('.video-chat-container').style.display = 'flex';
    document.querySelector('.twilio-loading').style.display = 'none';
  };

  joinRoom = () => {
    $.ajax({
      type: "PUT",
      url: this.joinMeetingUrl
    });
  };

  handleRemoteMicEnabled = (participant) => {
    let trackSid = Array.from(participant.videoTracks.values())[0].trackSid;
    document.querySelector(`#${trackSid} > #remote-muted-mic`).hidden = true;
  };

  handleRemoteMicDisabled = (participant) => {
    let trackSid = Array.from(participant.videoTracks.values())[0].trackSid;

    document.querySelector(`#${trackSid} > #remote-muted-mic`)
      .hidden = false;
  };

  getUserId(participant) {
    return participant.identity.split("-")[2];
  }

  isScreenShareTrack = (track) => track.name.includes('video-screen-share');
  isAudioTrack = (track) => track.kind === 'audio';

  handleParticipantTrackUnpublished(participant, track) {
    if (this.isScreenShareTrack(track)) {
      this.remoteScreenShareActive = false;
      handleStopShareScreen(this);
      this.addSpeakerToDominantView(participant);
    } else {
      this.removeVideosFromSpeakerView(participant);
    }
  }

  initRoomAndParticipantEvents = () => {
    this.room.participants.forEach((participant) => {
      if (this.localParticipant.identity !== participant.identity) {
        const userID = this.getUserId(participant);
        this.updateParticipantsList(userID, true);

        participant.on("trackSubscribed", (track) => {
          this.configureMicEventListeners(track, participant);
          this.findAndBuildNewMedia(track, participant);
        });

        participant.on("trackUnpublished", track => this.handleParticipantTrackUnpublished(participant, track));
      }
    });

    this.room.on("participantConnected", this.onParticipantConnected);

    this.room.on("participantDisconnected", this.onParticipantDisconnected);

    this.room.on("disconnected", this.handleDisconnectedRoom);
  };

  removeScreenShareVideo() {
    let speakerContainer = document.getElementsByClassName('speaker-view')[0];
    let screenShare = speakerContainer.querySelector('video[screenshare=true]');

    try {
      screenShare.remove();
    } catch {
      console.error("No screenshare was found for removal.");
    }
  }

  enableFullScreenBtnUI(enableFullscreen) {
    let toggleBtn = document.querySelector('.view-full-screen');
    let btnIcon = toggleBtn.children[1];

    if (enableFullscreen) {
      toggleBtn.firstChild.textContent = 'Exit Fullscreen';
      btnIcon.classList.remove('full-screen-icon');
      btnIcon.classList.add('minimize-icon');
    } else {
      toggleBtn.firstChild.textContent = 'View Fullscreen';
      btnIcon.classList.add('full-screen-icon');
      btnIcon.classList.remove('minimize-icon');
    }

    toggleBtn.dataset.fullScreenActive = enableFullscreen.toString();
  }

  toggleFullScreen() {
    let toggleBtn = document.querySelector('.view-full-screen');
    let active = (toggleBtn.dataset.fullScreenActive === 'true');

    if (!active) {
      this.setViewAsSpeaker(); // fullscreen currently only works for speaker view.
      this.enableFullScreenBtnUI(true);

      document.querySelector('.video-chat-container').classList.add('fullscreen');
      document.querySelector('.meeting-room-container').classList.add('meeting-room-fullscreen');

      document.querySelector('.remote-callers > .grid-container').classList.add('grid-container-fullscreen');
      document.querySelector('.twilio-container').style.width = "95%";

      let myVideo = document.getElementById('my-video');
      myVideo.classList.remove('grid-item');

      document.querySelector('.speaker-view').append(myVideo);

      this.setVideoGridSize('fullScreenView');
      this.showAllVideosInGrid();
      document.querySelector(".view-toggle-container").firstChild.style.display = 'none';
      this.resetGridVideoDisplay(true);
    } else {
      this.enableFullScreenBtnUI(false);

      document.querySelector('.video-chat-container').classList.remove('fullscreen');
      document.querySelector('.meeting-room-container').classList.remove('meeting-room-fullscreen');

      let myVideo = document.getElementById('my-video');
      myVideo.classList.add('grid-item');

      let remoteVideos = document.querySelector('.remote-callers > .grid-container');
      document.querySelector(".view-toggle-container").firstChild.removeAttribute('style');
      document.querySelector('.twilio-container').removeAttribute('style');
      remoteVideos.classList.remove('grid-container-fullscreen');
      remoteVideos.prepend(myVideo);
      myVideo.removeAttribute('style');
      this.setVideoGridSize('speakerView');
      this.showAllVideosInGrid();
      this.resetGridVideoDisplay();
    }
  }

  configureMicEventListeners(track, participant) {
    track.on('disabled', () => {
      if(this.isAudioTrack(track)) this.handleRemoteMicDisabled(participant);
    });

    track.on('enabled', () => {
      if(this.isAudioTrack(track)) this.handleRemoteMicEnabled(participant);
    });
  }

  removeSpeakerFromDominantView() {
    let speakerContainer = document.getElementsByClassName('speaker-view')[0];
    let screenShare = speakerContainer.querySelector('video[screenshare=true]');

    // if current speaker is a screenshare track, just remove the track
    if(screenShare) {
      screenShare.remove();
    }

    if(speakerContainer.querySelector('video[data-track-sid]')) {
      let currentSpeaker = speakerContainer.querySelector('video[data-track-sid]');
      let gridVideos = Array.from(document.querySelector(".twilio-container > .remote-callers > .grid-container").children);
      var itemMissingVideo = gridVideos.find( video => video.hasAttribute('hidden') );

      itemMissingVideo.appendChild(currentSpeaker);
      itemMissingVideo.hidden = false;
    }
  }

  addSpeakerToDominantView(participant) {
    let speakerContainer = document.getElementsByClassName('speaker-view')[0];
    let newSpeakerId = Array.from(participant.videoTracks.values())[0].trackSid;

    console.log('newSpeakerId:', newSpeakerId);
    let newSpeakerContainer = document.querySelector(`#${newSpeakerId}`);
    let newSpeaker = newSpeakerContainer.querySelector('video');
    newSpeakerContainer.hidden = true;
    speakerContainer.appendChild(newSpeaker);

    this.displaySpeakerName(true, this.localUserName);
  }

  // extract participant user name from string.
  participantUserName(trackName) {
    const trackNameValues = trackName.split("|");

    return trackNameValues[trackNameValues.length - 1];
  }

  // Button Actions
  setListenersForInteractiveButtons = () => {
    document.querySelector('.view-full-screen')
            .addEventListener('click', () => this.toggleFullScreen() );

    document.querySelector('#share').addEventListener("click", (e) => {
      this.toggleDevice(e, this.handleCaptureScreen.bind(this), () => {
        this.localParticipant.unpublishTrack(this.screenTrack);
        this.screenTrack.stop();
      });
    });

    document.querySelector('.speaker-view').addEventListener('mouseenter', () => {
      document.querySelector('.btn-container').classList.remove('move-down');
    });

    document.querySelector('.speaker-view').addEventListener('mouseleave', () => {
      document.querySelector('.btn-container').classList.add('move-down');
    });

    document.querySelector('#leave-call').addEventListener("click", handleStopConnect.bind(this, this.room));
    document.querySelector('.back-button').addEventListener("click", handleStopConnect.bind(this, this.room));

    document.querySelector('#cam').addEventListener("click", (e) => {
      this.toggleDevice(e,
        handleStartVideo.bind(this, this.localParticipant),
        handleStopVideo.bind(this, this.localParticipant));
    });

    document.querySelector('#mic').addEventListener("click", (e) => {
      this.toggleDevice(e, handleStartAudio.bind(this, this.localParticipant), handleStopAudio.bind(this, this.localParticipant));
    });

    document.querySelector('.chat-participants-toggle').addEventListener('click', () => {
      let btnLabel = document.querySelector('.chat-participants-toggle > ul > li:first-child');
      let btnSymbol = document.querySelector('.chat-participants-toggle > ul > li:nth-child(2)');

      if(btnLabel.textContent === 'View Participants') {
        btnLabel.textContent = 'View Chat';
        document.querySelector('#participants-container').style.display = 'block';
        document.querySelector('#chat-container').style.display = 'none';
        btnSymbol.classList.remove('multiple-users');
        btnSymbol.classList.add('chat-symbol');
      } else {
        btnLabel.textContent = 'View Participants';
        document.querySelector('#participants-container').style.display = 'none';
        document.querySelector('#chat-container').style.display = 'block';
        btnSymbol.classList.add('multiple-users');
        btnSymbol.classList.remove('chat-symbol');
      }
    });

    document.querySelector('.view-toggle').addEventListener("click", () => {
      let toggleButton = document.querySelector('.view-toggle > ul > li:first-child');
      // Disable switching to gallery view, during screen share
      if(this.galleryViewDisabled()) return;


      if(toggleButton.textContent === 'Gallery View') {
        this.setViewAsGallery();
      } else {
        this.setViewAsSpeaker();
        this.fillEmptySpeakerView();
      }
    });

    this.setGridViewObserver();
    document.querySelector('.slide-left').addEventListener("click",  this.handleLeftSlider.bind(this));
    document.querySelector('.slide-right').addEventListener("click", this.handleRightSlider.bind(this));
  };

  galleryViewDisabled() {
    let toggleButton = document.querySelector('.view-toggle > ul > li:first-child');

    return this.screenShareInProgress() && (toggleButton.textContent === 'Gallery View');
  }

  screenShareInProgress() {
    return this.remoteScreenShareActive || this.localScreenShareActive;
  }

  setViewAsSpeaker() {
    let toggleButton = document.querySelector('.view-toggle > ul > li:first-child');
    let viewIcon = document.querySelector('.view-toggle > ul > li:nth-child(2)');
    toggleButton.textContent  = 'Gallery View';
    viewIcon.classList.remove('speaker-view-icon');
    viewIcon.classList.add('gallery-icon');
    this.setVideoGridSize("speakerView");
    this.showAllVideosInGrid();

    // set up grid format
    document.querySelector('.speaker-view').style.display = 'block';
    document.querySelector('.grid-container').classList.remove('gallery-view-grid');
    document.querySelector('.control-panel').hidden = true;

    // move buttons to grid control panel
    let btnContainer = document.querySelector('.control-panel > .btn-container');
    let speakerControlPanel = document.querySelector('.speaker-view');
    speakerControlPanel.append(btnContainer);
    let rightArrow = document.querySelector(".right-slider-arrow");
    let leftArrow = document.querySelector(".left-slider-arrow");
    let remoteCallersGrid = document.querySelector(".remote-callers");
        remoteCallersGrid.append(rightArrow);
        remoteCallersGrid.append(leftArrow);

    this.resetGridVideoDisplay();
  }

  resetGridVideoDisplay(fullScreenActive = false) {
    this.lastRemoteVideo = this.numOfDisplayedVideos;

    const hideVideosFrom = fullScreenActive ? 1 : 2;

    Array.from(document.querySelectorAll( `.grid-item[data-remote-video]:nth-child(n+${this.numOfDisplayedVideos + hideVideosFrom})`))
         .forEach(el => el.style.display = 'none');
  }

  setViewAsGallery() {
    let toggleButton = document.querySelector('.view-toggle > ul > li:first-child');
    let viewIcon = document.querySelector('.view-toggle > ul > li:nth-child(2)');

    toggleButton.textContent = 'Speaker View';
    viewIcon.classList.remove('gallery-icon');
    viewIcon.classList.add('speaker-view-icon');

    this.removeSpeakerFromDominantView();

    // move buttons to grid control panel
    let btnContainer = document.querySelector('.speaker-view > .btn-container');
    let gridControlPanel = document.querySelector('.control-panel');
    gridControlPanel.append(btnContainer);
    let rightArrow = document.querySelector(".right-slider-arrow");
    let leftArrow = document.querySelector(".left-slider-arrow");
    let controlPanel = document.querySelector(".control-panel > .btn-container");
        controlPanel.append(rightArrow);
        controlPanel.append(leftArrow);

    document.querySelector('.speaker-view').style.display = 'none';
    this.displaySpeakerName(false);
    document.querySelector('.control-panel').hidden = false;
    document.querySelector('.grid-container').classList.add('gallery-view-grid');

    this.setVideoGridSize("galleryView");
    this.showAllVideosInGrid();
    this.resetGridVideoDisplay();
  }

  showAllVideosInGrid() {
    let videoContainers = Array.from(
      document.querySelectorAll(".grid-container > .grid-item")
    );

    videoContainers.forEach(container => container.style.display = "grid");
  }

  toggleDevice(e, enableDevice, disableDevice) {
    if(e.target.getAttribute('enabled') === 'false') {
      e.target.setAttribute('enabled', true);
      enableDevice();
    } else {
      e.target.setAttribute('enabled', false);
      disableDevice();
    }
  }

  handleLeftSlider() {
    if((this.lastRemoteVideo - this.numOfDisplayedVideos) < 1) return;

    this.lastRemoteVideo -= this.numOfDisplayedVideos;

    // display all videos after left range
    Array.from(document.querySelectorAll( '.grid-item[data-remote-video]'))
         .forEach(el => el.style.display = 'none');

    var startCountFrom;
    var countBackFrom;
    if(this.numOfDisplayedVideos === 3) {
      startCountFrom = 1;
      countBackFrom = 0;
    } else {
      // when not in fullscreen mode we have to skip the local users
      // video when hiding videos so we start from 2.
      startCountFrom = 2;
      countBackFrom = 1;
    }

    let leftRange = this.lastRemoteVideo - this.numOfDisplayedVideos;
    let rightRange = this.lastRemoteVideo;

    // hide all videos in the previous range.
    Array.from(
      document.querySelectorAll(
        `.grid-item[data-remote-video]:nth-child(n+${leftRange + startCountFrom}):nth-child(-n+${rightRange + countBackFrom})`
      )
    ).forEach(el =>  el.style.display = 'grid');

    this.toggleSliderVisibility();
  }

  handleRightSlider() {
    let items = Array.from(document.querySelectorAll('.grid-item[data-remote-video]'));

    if(items.length <= this.numOfDisplayedVideos) return;

    // first get all remote videos set display to none.
    Array.from(document.querySelectorAll( `.grid-item[data-remote-video]`))
         .forEach(el => el.style.display = 'none');

    this.lastRemoteVideo += this.numOfDisplayedVideos;

    if(this.numOfDisplayedVideos === 3) {
      var increaseBy = 1;
    } else {
      increaseBy = 2;
    }

    Array.from(
      document.querySelectorAll(`.grid-item[data-remote-video]:nth-child(n+${this.lastRemoteVideo - this.numOfDisplayedVideos + increaseBy}):nth-child(-n+${this.lastRemoteVideo + 1})`)
    ).forEach(el =>  el.style.display = 'grid');

    this.toggleSliderVisibility();
  }

  displayViewToggleAsEnabled() {
    let viewToggle = document.querySelector('.view-toggle');
        viewToggle.removeAttribute("title");
        viewToggle.removeAttribute("style");
  }

  displayViewToggleAsDisabled() { // style only
    let viewToggle = document.querySelector('.view-toggle');
        viewToggle.style.opacity = 0.3;
        viewToggle.style.cursor = 'not-allowed';
        viewToggle.setAttribute('title', 'Gallery view disabled during screen share');
  }

  hideScreenShareNotificationBox() {
    let notificationBox = document.querySelector('.notification-box');
        notificationBox.style.display = 'none';
  }

  displayScreenShareBtnAsEnabled() {
    // resets to original style which defaults as enabled.
    document.querySelector('#share')
            .removeAttribute("style");
  }

  displayLocalScreenShareBtnAsActive() {
    document.querySelector('#share')
            .style
            .backgroundColor = '#00c4ff';
  }

  displayRemoteScreenShareBtnAsDisabled() {
    let screenShareButton = document.querySelector('#share');
        screenShareButton.style.opacity = '0.3';
        screenShareButton.style.cursor = 'not-allowed';
  }

  handleCaptureScreen = () => {
    if (this.remoteScreenShareActive) return;

    // Create and preview your local screen.
    shareScreen(1000, 1600, (stream) => {
      this.screenTrack = new LocalVideoTrack(stream.getTracks()[0], { name: `video-screen-share|${generateUUID()}|${this.localUserName}` });
      this.displaySpeakerName(true, this.participantUserName(this.screenTrack.name));
      this.screenTrack.on("started", handleLocalShareScreen.bind(this, this));
      this.screenTrack.on("stopped", () =>  {
        handleStopShareScreen(this);
        this.fillEmptySpeakerView();
      });
    });
  };

  displaySpeakerName(toggle, userName = null) {
    let speakerNameDisplay = document.querySelector('.speaker-name');
        speakerNameDisplay.hidden = !toggle;
        speakerNameDisplay.firstChild.textContent = userName;
  }

  buildGridViewName(userName) {
    let nameTextBox = document.createElement('div');
        nameTextBox.textContent = userName;
        nameTextBox.className = 'participant-name';

    return nameTextBox;
  }

  buildMutedMicIcon() {
    let mutedMicIcon = document.createElement('div');
        mutedMicIcon.id = 'remote-muted-mic';
        mutedMicIcon.className = 'mic-disabled-icon-white';
        mutedMicIcon.hidden = false;

    return mutedMicIcon;
  }

  buildGridVideo(userName, track) {
    let remoteVideoContainer = document.createElement('div');
    let remoteVideo = track.attach();
        remoteVideo.style.display = 'block';

    remoteVideoContainer.className = 'grid-item';

    if(track.name.includes('mobile-video')) remoteVideo.classList.add('mobile-video');

    if(track.sid) {
      remoteVideoContainer.id = track.sid;
      remoteVideoContainer.dataset.remoteVideo = true;
      remoteVideo.dataset.trackSid = track.sid;
    } else {
      remoteVideoContainer.id = 'my-video';
    }

    remoteVideoContainer.dataset.participantName = this.participantUserName(track.name);

    remoteVideoContainer.append(this.buildGridViewName(userName));
    remoteVideoContainer.append(this.buildMutedMicIcon());
    remoteVideoContainer.append(remoteVideo);

    return remoteVideoContainer;
  }

  // Build new element for grid
  renderRemoteWebcam(track, participant) {
    if(!participant) return;
    if (this.isAudioTrack(track)) return;
    if (this.isScreenShareTrack(track)) return;

    const userName = this.participantUserName(track.name);
    let remoteVideo = this.buildGridVideo(userName, track, participant);
    let videoGrid = document.querySelector(".remote-callers > .grid-container");

    if(track instanceof LocalVideoTrack) {
      videoGrid.prepend(remoteVideo);
    } else {
      videoGrid.appendChild(remoteVideo);
    }
  }

  addRemoteVideoToDominantView(videoContainer) {
    let speakerContainer = document.querySelector('.speaker-view');
    let video = videoContainer.querySelector('video');

    videoContainer.hidden = true;
    speakerContainer.appendChild(video);

    this.displaySpeakerName(true, videoContainer.dataset.participantName);
  }

  fillEmptySpeakerView() {
    if(this.room.participants.size < 1) return;

    let videoExistsInSpeakerView = !!document.querySelector('.speaker-view > video');

    if(!videoExistsInSpeakerView) {
      let remoteVideos = document.querySelector('.remote-callers')
                                 .querySelectorAll('.grid-item[data-remote-video=true]');

      if(remoteVideos.length > 0) {
        let firstRemoteVideoFound = remoteVideos[0];
        this.addRemoteVideoToDominantView(firstRemoteVideoFound);
      }
    }
  }

  publishLocalAudioForNewParticipant() {
    // allow remote participant to receive your audio
    this.room.localParticipant.audioTracks.forEach((publication) => {
      this.localParticipant.publishTrack(publication.track);
    });
  }

  //! Handle Actions

  // Handle room and participant events
  onParticipantConnected = (participant) => {
    this.updateParticipantsList(this.getUserId(participant), true);

    participant.on("trackUnpublished", (track) => this.handleParticipantTrackUnpublished(participant, track));

    participant.on("trackSubscribed", (track) => {
      this.configureMicEventListeners(track, participant);
      this.publishLocalAudioForNewParticipant();
      this.findAndBuildNewMedia(track, participant);
    });
  };

  handleDisconnectedRoom = () => {
    // Detach the local media elements
    this.leftMeetingJob(this.localUserID);

    if (this.screenTrack) this.localParticipant.unpublishTrack(this.screenTrack);

    this.localParticipant.tracks.forEach((publication) => {
      const attachedElements = publication.track.detach();

      attachedElements.forEach((element) => element.remove());
    });
  };

  onParticipantDisconnected = (participant) => {
    // 1. Get participant data id
    // 2. If exists as active speaker, remove element.
    const userId = this.getUserId(participant);
    this.updateParticipantsList(userId, false);
    let gridItemId = Array.from(participant.videoTracks.values())[0].trackSid;
    document.querySelector(`#${gridItemId}`).remove();

    // 3. If exists as active speaker, remove element.
    this.removeVideosFromSpeakerView(participant);
    this.fillEmptySpeakerView();
  };

  removeVideosFromSpeakerView(participant) {
    let trackSid = Array.from(participant.videoTracks.values())[0].trackSid;
    let participantInSpeakerView = document.querySelector(`.speaker-view > [data-track-sid='${trackSid}']`);

    if(participantInSpeakerView) participantInSpeakerView.remove();

    this.displaySpeakerName(false);
  }

  addScreenShareToSpeakerView(track) {
    let screenShareVideo = track.attach();
        screenShareVideo.setAttribute("screenShare", true);

    this.displaySpeakerName(true,  this.participantUserName(track.name));

    $('.speaker-view').append(screenShareVideo);
  }

  removeScreenShareFromSpeakerView() {
    let speakerContainer = document.getElementsByClassName('speaker-view')[0];
    this.removeScreenShareVideo(speakerContainer);
    this.displayScreenShareBtnAsEnabled();
    this.displaySpeakerName(false);
  }

  renderScreenShare(track, participant) {
    if (this.isScreenShareTrack(track)) {
      this.remoteScreenShareActive = true;
      this.displayRemoteScreenShareBtnAsDisabled();
      this.removeSpeakerFromDominantView();
      this.addScreenShareToSpeakerView(track, participant);
      let viewToggleText = document.querySelector('.view-toggle').textContent;
      if(viewToggleText == 'Speaker View') this.setViewAsSpeaker(); // enforce switching back to speakerview if not already
      this.displayViewToggleAsDisabled();
    }
  }

  attachAudioTrack(track) {
    if (track.kind === "audio") track.attach();
  }

  findAndBuildNewMedia = (track, participant) => {
    this.renderScreenShare(track, participant);
    this.attachAudioTrack(track);
    this.renderRemoteWebcam(track, participant);
  };

  toggleSliderVisibility() {
    let numberOfGridItems = document.querySelectorAll('.grid-item[data-remote-video=true]').length;

    if(numberOfGridItems <= this.lastRemoteVideo) {
      document.querySelector('.slide-right').hidden = true;
    } else {
      document.querySelector('.slide-right').hidden = false;
    }

    if(this.lastRemoteVideo <= this.numOfDisplayedVideos) {
      document.querySelector('.slide-left').hidden = true;
    } else {
      document.querySelector('.slide-left').hidden = false;
    }
  }

  setGridViewObserver() {
    let self = this;
    let observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if(mutation.type == 'attributes') self.toggleSliderVisibility(mutation);
        if(mutation.type == 'childList') {
          self.toggleSliderVisibility(mutation);

          let newElementNumber = Array.from(document.querySelector('.grid-container').children).indexOf(mutation.addedNodes[0]);
          let dataset = document.querySelector('.grid-container').dataset;

          if(mutation.addedNodes.length && mutation.addedNodes[0]?.id !== 'my-video') {
            if(newElementNumber < dataset.leftRange || newElementNumber > dataset.rightRange) {
              mutation.addedNodes[0].style.display = 'none';
            }
          }

          let remoteVideos = mutation.target.querySelectorAll('.grid-item[data-remote-video=true]');
          if(remoteVideos.length == 1) {
            self.fillEmptySpeakerView();
          }
        }
      });
    });

    // configuration of the observer
    var config = { attributes: true, childList: true };
    var target = document.querySelector('.grid-container');

    observer.observe(target, config);
  }

  updateParticipantsList = (userId, joined) => {
    var currentUserId = $("#networking-rooms").data("current-user-id");
    if (parseInt(userId) === currentUserId) return;

    if (joined) {
      setTimeout(() => {
        requestInfoParticipant()
          .then((result) => {
            $("#participants")[0].innerHTML = result;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1000);

      return;
    } else {
      // remove participant from list
      document.getElementById(`participant-${userId}`).remove();
    }
  };

  leftMeetingJob = (userID) => {
    const ua = navigator.userAgent;
    const isFirefox = ua.match(/firefox/gi);
    const isSafari = ua.indexOf('safari') > 0 && ua.indexOf('chrome') === -1;
    const data = { user_id: userID };

    if (isSafari && window.fetch) {
      fetch(this.leftMeetingURL, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        keepalive: true
      });
    } else {
      $.ajax({
        type: "PUT",
        async: !(isFirefox || isSafari),
        url: this.leftMeetingURL,
        data: data
      });
    }
  };
}
