import TwilioV2 from "./twilioV2";
import TwilioV1 from "./twilioV1";
import requestVideoToken from "./request_video_token";
import { checkDeviceSupport } from "../components";

const handleVideoCall = async (token, videoMeetingId) => {
  window.video = video();
  window.video.startVideoCall(token, videoMeetingId);
};

const video = () => {
  const { twilioV2Enabled } = $(".meeting-room").data();

  return new (twilioV2Enabled ? TwilioV2 : TwilioV1)();
};

const initVideo = () => {
  if ($(".meeting-room").length > 0) {
    $('.meeting-room .loading-spinner').removeClass('d-none');
    $('.meeting-room .video-section').addClass('d-none');
    $('.meeting-room .section-chat').addClass('d-none');

    var $userSignedIn = document.querySelector('meta[name="user-signed-in"]');

    if (!$userSignedIn || $userSignedIn.content === "0") {
      return;
    }

    checkDeviceSupport((_response) => {
      var meetingId = $(".meeting-room").data("meeting-id");
      var type = $(".meeting-room").data("type-meeting");

      requestVideoToken(meetingId, type)
        .then((result) => {
          handleVideoCall(result.token, result.videoMeetingId);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
};

$(document).off("DOMContentLoaded", initVideo).on("DOMContentLoaded", initVideo);
