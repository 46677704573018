import Panzoom from '@panzoom/panzoom'

$(function() {
  const elem = document.getElementById('panzoom')
  if (elem !== null) {
    const panzoom = Panzoom(elem, {
      maxScale: 5,
      minScale: 1
    });
    panzoom.pan(10, 10);
    panzoom.zoom(1, { animate: true });

    const buttonZoomIn = document.getElementById('zoom-in')
    const buttonZoomOut = document.getElementById('zoom-out')
    buttonZoomIn.addEventListener('click', panzoom.zoomIn);
    buttonZoomOut.addEventListener('click', panzoom.zoomOut);
    elem.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)
  }
});
